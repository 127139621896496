<template>
    <div>
        <el-collapse v-model="collapsedComponents" accordion>
            <template
                v-for="(component, componentIndex) in componentComponents"
                :key="componentIndex"
            >
                <el-collapse-item>
                    <template #title>
                        <div class="d-flex align-items-center w-100 pe-3">
                            {{ component.title.toUpperCase() }}
                            <el-button
                                v-if="
                                    addableListItem &&
                                    componentComponents.length > 1
                                "
                                @click="
                                    removeListItem(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                                class="ms-auto"
                                type="danger"
                                :icon="Delete"
                                :disabled="
                                    pageStore.updateInProgress == 'in-progress'
                                "
                                plain
                                circle
                            />
                        </div>
                    </template>
                    <el-form
                        label-position="left"
                        label-width="50%"
                        :model="component"
                    >
                        <!-- If component has text -->
                        <el-form-item
                            v-if="component.textRaw || component.textRaw == ''"
                            label="Text"
                        >
                            <QuillEditorComponent
                                v-model="component.textRaw"
                                @change="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has text end -->

                        <!-- If component has mainly prize tab text -->
                        <el-form-item
                            v-if="
                                (component && component?.mainlyPrizeTabText) ||
                                component?.mainlyPrizeTabText == ''
                            "
                            label="Főnyeremény tab cím"
                        >
                            <el-input
                                v-model="component.mainlyPrizeTabTextRaw"
                                @input="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has mainly prize tab text end -->

                        <!-- If component has monthly prize tab text -->
                        <el-form-item
                            v-if="
                                component.monthlyPrizeTabText ||
                                component.monthlyPrizeTabText == ''
                            "
                            label="Havi nyeremény tab cím"
                        >
                            <el-input
                                v-model="component.monthlyPrizeTabTextRaw"
                                @input="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has monthly prize tab text end -->

                        <!-- If component has weekly prize tab text -->
                        <el-form-item
                            v-if="
                                component.weeklyPrizeTabText ||
                                component.weeklyPrizeTabText == ''
                            "
                            label="Heti nyeremény tab cím"
                        >
                            <el-input
                                v-model="component.weeklyPrizeTabTextRaw"
                                @input="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has weekly prize tab text end -->

                        <!-- If component has daily prize tab text -->
                        <el-form-item
                            v-if="
                                component.dailyPrizeTabText ||
                                component.dailyPrizeTabText == ''
                            "
                            label="Napi nyeremény tab cím"
                        >
                            <el-input
                                v-model="component.dailyPrizeTabTextRaw"
                                @input="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has daily prize tab text end -->

                        <!-- If component has three state text -->
                        <el-form-item
                            v-if="
                                component.textStateOne ||
                                component.textStateOne == ''
                            "
                            label="State one"
                        >
                            <el-input
                                v-model="component.textStateOneRaw"
                                @input="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has three state text end -->

                        <!-- If component has three state text -->
                        <el-form-item
                            v-if="
                                component.textStateTwo ||
                                component.textStateTwo == ''
                            "
                            label="State two"
                        >
                            <el-input
                                v-model="component.textStateTwoRaw"
                                @input="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has three state text end -->

                        <!-- If component has three state text -->
                        <el-form-item
                            v-if="
                                component.textStateThree ||
                                component.textStateThree == ''
                            "
                            label="State three"
                        >
                            <el-input
                                v-model="component.textStateThreeRaw"
                                @input="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has three state text end -->

                        <!-- If component has to one state link -->
                        <el-form-item
                            v-if="
                                component.toStateOne ||
                                component.toStateOne == ''
                            "
                            label="To state one"
                        >
                            <el-input
                                v-model="component.toStateOne"
                                @input="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has to one state link end -->

                        <!-- If component has to two state link -->
                        <el-form-item
                            v-if="
                                component.toStateTwo ||
                                component.toStateTwo == ''
                            "
                            label="To state two"
                        >
                            <el-input
                                v-model="component.toStateTwo"
                                @input="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has to two state link end -->

                        <!-- If component has href link -->
                        <el-form-item
                            v-if="component.href || component.href == ''"
                            label="Href"
                        >
                            <el-input
                                v-model="component.href"
                                @input="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has href link end -->

                        <!-- If component has to three state link -->
                        <el-form-item
                            v-if="
                                component.toStateThree ||
                                component.toStateThree == ''
                            "
                            label="To state three"
                        >
                            <el-input
                                v-model="component.toStateThree"
                                @input="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has to three state link end -->

                        <!-- If component has label -->
                        <el-form-item
                            v-if="
                                component.labelRaw || component.labelRaw == ''
                            "
                            label="Label"
                        >
                            <el-input
                                v-model="component.labelRaw"
                                controls-position="right"
                                type="textarea"
                                autosize
                                @change="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has label end -->

                        <!-- If component has placeholder -->
                        <el-form-item
                            v-if="
                                component.placeholderRaw ||
                                component.placeholderRaw == ''
                            "
                            label="Placeholder"
                        >
                            <el-input
                                v-model="component.placeholder"
                                controls-position="right"
                                type="textarea"
                                autosize
                                @change="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has placeholder end -->

                        <!-- If component has checkboxText -->
                        <el-form-item
                            v-if="
                                component.checkboxTextRaw ||
                                component.checkboxTextRaw == ''
                            "
                            label="Checkbox text"
                        >
                            <QuillEditorComponent
                                v-model="component.checkboxTextRaw"
                                @change="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has checkboxText end -->

                        <el-form-item
                            v-if="component.checkboxShow !== undefined"
                            label="Checkbox visibility"
                        >
                            <el-switch v-model="component.checkboxShow" />
                        </el-form-item>

                        <!-- If component has image content -->
                        <el-form-item
                            v-if="
                                component.imageContent == '' ||
                                component.imageContent?.length
                            "
                            label="Image"
                        >
                            <ImageUploader
                                :public="true"
                                v-model="component.imageContent"
                                @change="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has image content end -->

                        <!-- If component has description content -->
                        <el-form-item
                            v-if="
                                component.descriptionContentRaw ||
                                component.descriptionContentRaw == ''
                            "
                            label="Description"
                        >
                            <QuillEditorComponent
                                v-model="component.descriptionContentRaw"
                                @change="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has description content end -->

                        <!-- If component has modalTitle -->
                        <el-form-item
                            v-if="
                                component.modalTitleRaw ||
                                component.modalTitleRaw == ''
                            "
                            label="Modal title"
                        >
                            <el-input
                                v-model="component.modalTitleRaw"
                                controls-position="right"
                                type="textarea"
                                autosize
                                @change="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has modalTitle end -->

                        <!-- If component has modalDescription -->
                        <el-form-item
                            v-if="
                                component.modalDescriptionRaw ||
                                component.modalDescriptionRaw == ''
                            "
                            label="Modal description"
                        >
                            <el-input
                                v-model="component.modalDescriptionRaw"
                                controls-position="right"
                                type="textarea"
                                autosize
                                @change="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has modalDescription end -->

                        <!-- If component has modalLeftButtonText -->
                        <el-form-item
                            v-if="
                                component.modalLeftButtonTextRaw ||
                                component.modalLeftButtonTextRaw == ''
                            "
                            label="Modal left button"
                        >
                            <el-input
                                v-model="component.modalLeftButtonTextRaw"
                                controls-position="right"
                                type="textarea"
                                autosize
                                @change="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has modalLeftButtonText end -->

                        <!-- If component has modalRightButtonText -->
                        <el-form-item
                            v-if="
                                component.modalRightButtonTextRaw ||
                                component.modalRightButtonTextRaw == ''
                            "
                            label="Modal right button"
                        >
                            <el-input
                                v-model="component.modalRightButtonTextRaw"
                                controls-position="right"
                                type="textarea"
                                autosize
                                @change="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has modalRightButtonText end -->

                        <!-- If component has source -->
                        <el-form-item
                            v-if="component.src == '' || component.src?.length"
                            label="Image"
                        >
                            <ImageUploader
                                :public="true"
                                v-model="component.src"
                                @change="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has source end -->

                        <!-- If component has video source -->
                        <el-form-item
                            v-if="
                                component.videoSrc == '' ||
                                component.videoSrc?.length
                            "
                            label="Youtube link"
                        >
                            <el-input
                                v-model="component.videoSrc"
                                controls-position="right"
                                @change="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has video source end -->

                        <!-- If component has images by breakpoints -->
                        <el-form-item
                            v-if="component.images"
                            v-for="(image, imageIndex) in component.images"
                            :key="imageIndex"
                            :label="image.label"
                        >
                            <ImageUploader
                                :public="true"
                                v-model="image.src"
                                @change="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- If component has images by breakpoints end -->

                        <!-- Component styles options -->
                        <el-form-item
                            v-if="component.style"
                            v-for="(property, propertyIndex) in component.style"
                            :key="propertyIndex"
                            :label="property.label"
                        >
                            <el-input
                                v-if="property.inputType === EInputType.TEXT"
                                v-model="property.value"
                                controls-position="right"
                                @change="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />

                            <el-input-number
                                v-if="property.inputType === EInputType.NUMBER"
                                v-model="property.value"
                                controls-position="right"
                                @change="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />

                            <ColorPickerSelect
                                v-if="
                                    property.inputType ===
                                    EInputType.COLOR_PICKER
                                "
                                v-model="property.value"
                                @change="
                                    inputChangeHandler(component);
                                    pageStore.updateProgress(
                                        EUpdateProgress.IN_PROGRESS
                                    );
                                "
                            />
                        </el-form-item>
                        <!-- Component styles options end -->
                    </el-form>

                    <!-- Component has components -->
                    <div
                        v-if="component.components?.length"
                        class="mt-5 mb-3 small text-black-50"
                    >
                        Component components:
                    </div>
                    <PrizificPageBuilderToolComponent
                        v-if="component.components?.length"
                        :componentComponents="component.components"
                        @change="inputChangeHandler(component)"
                    />
                    <!-- Component has components end -->
                </el-collapse-item>
            </template>
        </el-collapse>

        <div class="text-center pt-3">
            <el-button
                v-if="addableListItem"
                @click="
                    addListItem();
                    pageStore.updateProgress(EUpdateProgress.IN_PROGRESS);
                "
                type="primary"
                :icon="Plus"
                circle
                plain
                :disabled="pageStore.updateInProgress == 'in-progress'"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import {
    ElForm,
    ElFormItem,
    ElInput,
    ElInputNumber,
    ElColorPicker,
} from "element-plus";
import { EUpdateProgress, usePagesStore } from "@/store/pages";
import { EInputType } from "~~/enums/PrizificPageBuilder/EInputType";
import debounce from "lodash.debounce";
import ColorPickerSelect from "./ColorPickerSelect.vue";
import { ArrowUp, ArrowDown, Delete, Plus } from "@element-plus/icons-vue";
import { useCampaignDataStore } from "~~/store/campaign/campaignData";
import { useSetupPagesStore } from "~~/store/pageSetup/pageSetupStore";
import { newGameStep } from "~~/entities/pages/home/sections/GameSteps/components/NewGameStep/NewGameStep";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";

interface Props {
    section: any;
    componentComponents: Array<any>;
    addableListItem: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

const collapsedComponents = ref([]);

/**
 * Use page store
 */
const pageStore = usePagesStore();
/**
 * Use page store
 */
const pageSetupStore = useSetupPagesStore();
/**
 * Use campaign data store
 */
const campaignDataStore = useCampaignDataStore();

/**
 * Check section has components
 */
const hasComponentComponents = computed(() => {
    return props.componentComponents?.length;
});

/**
 * Handle input on change
 * @param component
 */
const inputChangeHandler = debounce((component: any) => {
    pageStore.updateComponent(component);
}, 2000);

/**
 * Handle click add item button
 */
async function addListItem() {
    if (props.componentComponents[0]?.type) {
        const { $pageBuilderFactory }: any = useNuxtApp();
        const component = $pageBuilderFactory.createPlainComponent(
            props.componentComponents[0]?.type
        );

        await pageSetupStore
            .saveComponent(
                component,
                props.section.id,
                useActualPage().id,
                campaignDataStore.campaignUUId
            )
            .then(() => {
                pageStore.getPageFromServer(EComponents.HOME_PAGE);
            });
    }
}

/**
 * Handle click remove item button
 */
function removeListItem(component: any) {
    pageStore.removeComponent(component, useActualPage().getType());
}
</script>
<style lang="scss"></style>
