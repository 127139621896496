<template>
    <!-- Game steps -->
    <section
        class="youtube-videos pt-7 pb-7"
        :style="[youtubeVideosWrapperStyleObject]"
    >
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-10 offset-md-1">
                    <h2
                        class="position-relative d-block fw-900 font-italic pb-2 text-center"
                        :style="titleWrapperStyleObject"
                        v-html="section.sectionTitleRaw"
                    ></h2>
                    <div
                        class="mt-5 text-center"
                        :style="paragraphWrapperStyleObject"
                        v-html="section.sectionParagraphRaw"
                    ></div>
                    <div class="mt-6 pt-4 pb-5">
                        <client-only>
                            <!-- Swiper -->
                            <swiper
                                class="youtube-video-swiper"
                                :style="swiperPaginationStyleObject"
                                :modules="modules"
                                :breakpoints="data.breakpoints"
                                :pagination="data.pagination"
                                :navigation="data.navigation"
                            >
                                <swiper-slide
                                    v-for="video in section.components"
                                >
                                    <iframe
                                        class="youtube-video"
                                        :style="[
                                            getStyle(video.style, 'video'),
                                        ]"
                                        :src="video.videoSrc"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerpolicy="strict-origin-when-cross-origin"
                                        allowfullscreen
                                    ></iframe>
                                </swiper-slide>

                                <!-- Swiper prev/next buttons -->
                                <div
                                    class="swiper-button-prev d-none d-lg-block"
                                    :style="swiperArrowColorStyleObject"
                                ></div>
                                <div
                                    class="swiper-button-next d-none d-lg-block"
                                    :style="swiperArrowColorStyleObject"
                                ></div>
                                <!-- Swiper prev/next buttons end -->

                                <!-- Swiper pagination -->
                                <div
                                    :style="swiperPaginationStyleObject"
                                    class="swiper-pagination d-block d-lg-none"
                                ></div>
                                <!-- Swiper pagination end -->
                            </swiper>
                            <!-- Swiper end -->
                        </client-only>
                    </div>
                </div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
    <!-- Game steps end -->
</template>

<script setup lang="ts">
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "~~/interfaces/PrizificPageBuilder/IProperty";
import { useCampaignDataStore } from "~~/store/campaign/campaignData";
import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const runtime = useRuntimeConfig();

/**
 * Props interface
 */
interface Props {
    section: IComponent<EComponents> & any;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();
const campaignDataStore = useCampaignDataStore();
const runtimeConfig = useRuntimeConfig();

const modules = [Navigation, Pagination, A11y];
const data = reactive({
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
        dynamicBullets: true,
    },
    breakpoints: {
        1199: {
            slidesPerView: 3,
            spaceBetween: 51,
            centeredSlides: false,
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 51,
            centeredSlides: true,
        },
        640: {
            slidesPerView: 2,
            spaceBetween: 32,
            centeredSlides: true,
        },
        320: {
            slidesPerView: 2,
            spaceBetween: 16,
            centeredSlides: true,
        },
    },
});

/**
 * Create youtube videos wrapper inline style object
 * @returns {Object} styleObject
 */
const youtubeVideosWrapperStyleObject = computed(() => {
    return getStyle(props.section.style, "wrapper");
});

const imageXs = `url('${useMediaUrl(props.section.images[0]?.src)}')`;
const imageSm = `url('${useMediaUrl(props.section.images[1]?.src)}')`;
const imageMd = `url('${useMediaUrl(props.section.images[2]?.src)}')`;
const imageLg = `url('${useMediaUrl(props.section.images[3]?.src)}')`;
const imageXl = `url('${useMediaUrl(props.section.images[4]?.src)}')`;
const imageXxl = `url('${useMediaUrl(props.section.images[5]?.src)}')`;

/**
 * Create youtube videos title inline style object
 * @returns {Object} styleObject
 */
const titleWrapperStyleObject = computed(() => {
    return getStyle(props.section.style, "title");
});

/**
 * Create youtube videos paragraph inline style object
 * @returns {Object} styleObject
 */
const paragraphWrapperStyleObject = computed(() => {
    return getStyle(props.section.style, "paragraph");
});

/**
 * Get swiper pagination style
 * @returns {Object} styleObject
 */
const swiperPaginationStyleObject = computed(() => {
    const swiperPagination = getStyle(props.section.style, "swiperPagination");

    const swiperPaginationActive = getStyle(
        props.section.style,
        "swiperPaginationActive"
    );

    const style = {
        "--swiper-pagination-bullet": swiperPagination?.color,
        "--swiper-pagination-color-active": swiperPaginationActive.color,
    };

    return style;
});

/**
 * Get swiper arrow style
 * @returns {Array<any>} styleObject
 */
const swiperArrowColorStyleObject = computed(() => {
    const swiperArrow = getStyle(props.section.style, "swiperArrow");

    const style = {
        color: swiperArrow.color,
        "--swiper-navigation-size": swiperArrow.fontSize,
    };

    return style;
});
</script>

<style lang="scss">
.youtube-videos {
    min-height: 56px;
    background-image: v-bind("imageXs");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 576px) {
        background-image: v-bind("imageSm");
    }

    @media (min-width: 768px) {
        background-image: v-bind("imageMd");
    }

    @media (min-width: 992px) {
        background-image: v-bind("imageLg");
    }

    @media (min-width: 1200px) {
        background-image: v-bind("imageXl");
    }

    @media (min-width: 1400px) {
        background-image: v-bind("imageXxl");
    }

    .youtube-video-swiper {
        .swiper-wrapper {
            padding-bottom: 24px;
        }

        .swiper-button-disabled {
            display: none !important;
        }

        .swiper-button-prev,
        .swiper-button-next {
            &::after {
                font-weight: bold;
            }
        }

        .swiper-pagination {
            .swiper-pagination-bullet {
                background-color: var(--swiper-pagination-bullet);

                &.swiper-pagination-bullet-active {
                    background-color: var(--swiper-pagination-color-active);
                }
            }
        }
    }

    .youtube-video {
        width: 100%;
        height: 210px;

        @media (min-width: 576px) {
            height: 290px;
        }

        @media (min-width: 768px) {
            height: 210px;
        }

        @media (min-width: 992px) {
            height: 210px;
        }

        @media (min-width: 1200px) {
            height: 210px;
        }

        @media (min-width: 1400px) {
            height: 210px;
        }
    }
}
</style>
