import { defineStore } from "pinia";
import { useCampaignDataStore } from "../campaign/campaignData";
import { useSetupPagesStore } from "../pageSetup/pageSetupStore";
import { IComponent } from "~/interfaces/PrizificPageBuilder/IComponent";
import { EComponents } from "~/enums/PrizificPageBuilder/EComponents";
import { usePagesStore } from "../pages";

interface SetupSectionState {
    inProgress: boolean;
    sectionSetupCompleted: boolean;
}

export const useSetupSectionStore = defineStore("setupSection", {
    state: (): SetupSectionState => {
        return {
            inProgress: false,
            sectionSetupCompleted: false,
        };
    },
    getters: {},
    actions: {
        async availableSectionTypes() {
            const { $pageBuilderFactory }: any = useNuxtApp();

            const componentWhitelist = [
                EComponents.YOUTUBE_VIDEO,
                EComponents.IMAGE_TEXT_CONTENT,
                EComponents.USER_CONSENTING,
                EComponents.PRIZES_BY_TYPE,
                EComponents.PRIZES_MINION,
                EComponents.PRIZES_GROUP_BY_TYPE,
                EComponents.VOTE,
                EComponents.VOTE_DEFAULT,
                EComponents.GAME_STEPS_SZENTKIRALYI,
                EComponents.DISCOUNT_COUPONS,
                EComponents.CONTACT_WITH_PRIVACY_POLICY,
                EComponents.CONTACT_PRIVACY_POLICY,
                EComponents.FOOTER_SMALL,
                EComponents.TEXT_BUTTON_SECTION,
                EComponents.MAPS,
            ];

            return $pageBuilderFactory
                .getAvailableComponentTypes()
                .filter((componentType: any) => {
                    return componentWhitelist.includes(componentType);
                }) as Array<EComponents>;
        },
        async setupSection(
            sectionType: EComponents,
            actualPageType: EComponents,
            order: any
        ) {
            const { $pageBuilderFactory }: any = useNuxtApp();

            const campaignDataStore = useCampaignDataStore();
            const pageSetupStore = useSetupPagesStore();
            const pageStore = usePagesStore();

            this.inProgress = true;

            const campaignUuid = await campaignDataStore.obtainCampaignUUId();
            const availableSectionTypes = await this.availableSectionTypes();
            // console.log("sectionType", sectionType, availableSectionTypes);
            if (!availableSectionTypes.includes(sectionType)) {
                throw new Error(
                    `This sectionType: '${sectionType}' is not able to setup, choose one from 'availableSectionTypes'`
                );
            }

            const component =
                $pageBuilderFactory.createPlainComponent(sectionType);
            const pageId = await pageStore.getPageIdByType(actualPageType);

            await pageSetupStore
                .recursiveGetPayloadOfAllComponent(
                    component,
                    null,
                    campaignUuid,
                    pageId,
                    order
                )
                .then(() => {
                    pageStore.getPageFromServer(actualPageType);
                });
        },
    },
});
