<template>
    <li class="nav-item d-flex align-items-center">
        <NuxtLink
            v-if="
                campaignDataStore.isRegistrationRequired &&
                !authStore.isAuthenticated &&
                !loginRegistrationStore.isRegistered
            "
            :style="navItemStyleObject"
            :class="['nav-link', component.classNames]"
            :to="useDynamicLink(component.toStateOne)"
            @mouseover="toggleStyle($event, true)"
            @mouseleave="toggleStyle($event, false)"
        >
            {{ component.textStateOne }}
        </NuxtLink>
        
        <NuxtLink
            v-if="authStore.isAuthenticated"
            :style="navItemStyleObject"
            :class="['nav-link', component.classNames]"
            :to="useDynamicLink(component.toStateTwo)"
            @mouseover="toggleStyle($event, true)"
            @mouseleave="toggleStyle($event, false)"
        >
            {{ component.textStateTwo }}
        </NuxtLink>

        <NuxtLink
            v-if="
                campaignDataStore.isRegistrationRequired &&
                !authStore.isAuthenticated &&
                loginRegistrationStore.isRegistered
            "
            :style="navItemStyleObject"
            :class="['nav-link', component.classNames]"
            :to="useDynamicLink(component.toStateThree)"
            @mouseover="toggleStyle($event, true)"
            @mouseleave="toggleStyle($event, false)"
        >
            {{ component.textStateThree }}
        </NuxtLink>
    </li>
</template>

<script setup lang="ts">
import { useAuthStore } from "~~/store/user/auth";
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { getStyle } from "../../composables/styleHelper";
import { useLoginRegistrationStore } from "~~/store/components/loginRegistration/loginRegistration";
import { INavItemWithThreeState } from "~~/interfaces/PrizificPageBuilder/INavItemWithThreeState";

const authStore: any = useAuthStore();
const campaignDataStore = useCampaignDataStore();
const loginRegistrationStore = useLoginRegistrationStore();

interface Props {
    component: IComponent<EComponents> & INavItemWithThreeState;
}

/**
 * Define props
 */
const props = defineProps<Props>();

const navbarButton = useCookie("navbarButton", {
    default: () => {
        return { isRegistered: false };
    },
    watch: true,
});

loginRegistrationStore.isRegistered = navbarButton.value.isRegistered;

if (
    navbarButton.value.isRegistered == undefined ||
    navbarButton.value.isRegistered == false
) {
    loginRegistrationStore.setActiveComponent(EComponents.REGISTRATION);
} else if (navbarButton.value.isRegistered == true) {
    loginRegistrationStore.setActiveComponent(EComponents.LOGIN);
}

/**
 * Create nav item inline style object
 * @returns {Object} styleObject
 */
const navItemStyleObject = computed(() => {
    let style: any[] = [];

    if (props.component.style.length) {
        style = props.component.style.map((item) => {
            if (item.state === "default") {
                return {
                    [item.property]: `${item.value}${
                        item.unit ? item.unit : ""
                    }`,
                };
            }
        });
    }

    return style;
});

/**
 * Get icon size
 */
const iconSize = computed(() => {
    return getStyle(props.component.style, "iconSize");
});

//TODO: remove toggleStyle and add newer like in Prizes
/**
 * Handle mouseover/mouseleave
 * @param {MouseEvent} event
 * @param {Boolean} isHover
 */
function toggleStyle(event: MouseEvent, isHover: Boolean) {
    let target = event.target as HTMLButtonElement;

    if (isHover) {
        props.component.style.forEach((item) => {
            if (item.state === "hover") {
                target.style[item.property] = `${item.value}${
                    item.unit ? item.unit : ""
                }`;
            }
        });
    } else {
        props.component.style.forEach((item) => {
            if (item.state === "default") {
                target.style[item.property] = `${item.value}${
                    item.unit ? item.unit : ""
                }`;
            }
        });
    }
}
</script>
<style lang="scss" scoped>
.nav-link {
    position: relative;
    line-height: 1;
    padding: 0;
    cursor: pointer;
    color: transparent; //TODO: CONNECT WITH PAGE BUILDER
    transition: color 0.25 ease-in-out, background 0.25 ease-in-out;

    &.underline {
        &:hover {
            &::after {
                position: absolute;
                content: "";
                width: calc(100% - 16px);
                height: 3px;
                background-color: #facc6b;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}
</style>
