<template>
    <nav
        id="mobile-navbar"
        :style="navbarStyle"
        class="navbar position-sticky sticky-top d-block d-xl-none"
    >
        <div class="container px-2 justify-content-start">
            <!-- Brand logo -->
            <NuxtLink :to="useDynamicLink('')">
                <img :src="useMediaUrl(logoSrc)" :style="logoStyle" alt="" />
            </NuxtLink>
            <!-- Brand logo end -->

            <!-- Navbar items -->
            <div ref="navbar" class="ms-auto" id="navbar">
                <ul class="navbar-nav ms-auto mb-1">
                    <template
                        v-for="(
                            component, componentIndex
                        ) in section.getComponents()"
                        :key="componentIndex"
                    >
                        <NavItem
                            v-if="(component as any).type === EComponents.NAV_ITEM && componentIndex == section.getComponents().length - 1"
                            :component="(component as any)"
                        />
                    </template>
                </ul>
            </div>
            <!-- Navbar items end -->

            <MobileNavbarBottom :section="section" />

            <!-- Prizific page builder tool -->
            <client-only>
                <PrizificPageBuilderTool v-if="editable" :section="section" />
            </client-only>
            <!-- Prizific page builder tool end -->
        </div>
    </nav>
</template>

<script setup lang="ts">
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { INavItem } from "~~/interfaces/PrizificPageBuilder/INavItem";

const runtime = useRuntimeConfig();

interface Props {
    section: IComponent<EComponents>;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Navbar computed style
 */
const navbarStyle = computed(() => {
    return getStyle(props.section.style, "navbar");
});

/**
 * Brand logo computed style
 */
const logoStyle = computed(() => {
    let style: any[] = [];

    props.section.getComponents().forEach((component: any) => {
        if (component.type === EComponents.IMAGE) {
            style = component.style.map((item: any) => {
                return {
                    [item.property]: `${item.value}${
                        item.unit ? item.unit : ""
                    }`,
                };
            });
        }
    });

    return style;
});

/**
 * Brand logo image computed source
 */
const logoSrc = computed(() => {
    let image = "";

    props.section.getComponents().forEach((component: any) => {
        if (component.type === EComponents.IMAGE) {
            image = component?.src;
        }
    });

    return image;
});
</script>

<style lang="scss" scoped>
.navbar {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}
</style>
