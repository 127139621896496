<script lang="ts" setup>
import ApCodeInput from "~~/components/APCodeInput/ApCodeInput.vue";
import { EPurchaseType } from "~~/enums/Campaign/EPurchaseType";
import { ECodeUploadFormItem } from "~~/enums/PrizificPageBuilder/ECodeUploadFormItem";
import { useCampaignDataStore } from "~~/store/campaign/campaignData";
import {
    DynamicField,
    useCodeUploadStore,
} from "~~/store/components/codeUpload/codeUpload";

interface Props {
    entryType: EPurchaseType;
    codeUploadComponent: any;
    rawComponent: any;
    modelValue: any;
}
const props = defineProps<Props>();

const codeUploadStore = useCodeUploadStore();
const campaignDataStore = useCampaignDataStore();

await codeUploadStore.getDynamicFieldsByType(props.entryType);

const promoProductsDialog = ref(false);

const inputPurchasedPromotionPorductTexts = computed(() => {
    return getFormItemText(
        props.codeUploadComponent,
        ECodeUploadFormItem.PURCHASED_PROMOTION_PRODUCT
    );
});

const inputApCodeTexts = computed(() => {
    return getFormItemText(
        props.codeUploadComponent,
        ECodeUploadFormItem.AP_CODE
    );
});

const inputPurchaseDateApCodeTexts = computed(() => {
    return getFormItemText(
        props.codeUploadComponent,
        ECodeUploadFormItem.PURCHASE_DATE
    );
});
const inputPurchaseTimeTexts = computed(() => {
    return getFormItemText(
        props.codeUploadComponent,
        ECodeUploadFormItem.PURCHASE_TIME
    );
});
const inputReceiptNumberTexts = computed(() => {
    return getFormItemText(
        props.codeUploadComponent,
        ECodeUploadFormItem.RECEIPT_NUMBER
    );
});

/**
 * This help to make a uniq id for the dynamic Filed
 */
function dynamicFieldId(dynamicField: DynamicField): string {
    return dynamicField.id.toString();
}

const validateReceiptImage = (rule: any, value: any, callback: any) => {
    if (internalModel.value.receiptImage?.length == 0) {
        callback(new Error("Kötelező mező"));
    } else {
        callback();
    }
};
let timeOfPurchaseIsFilledUp = false;
const validateTimeOfPurchase = (rule: any, value: any, callback: any) => {
    //console.log("value", value);
    if (value?.length == 0) {
        timeOfPurchaseIsFilledUp = false;
        callback(new Error("Kötelező mező"));
    } else if (value.length != 5) {
        if (timeOfPurchaseIsFilledUp) {
            callback(new Error("Valami nem stimmel kérjük javítsd"));
        }
    } else {
        timeOfPurchaseIsFilledUp = true;
        callback();
    }
};

/**
 * We need to converted for use it for template input number min max
 *
 * @param validation "required|min:1|max:3"
 * @returns [{type: "required"}, {type: "min", value: 1}, {type: "max", value: 3}]
 */
function dynamicInputRuleValidationMaker(validation: string) {
    const validations = validation.split("|").map((rule) => {
        const [type, value] = rule.split(":");
        if (value !== undefined) {
            return { type, value: parseInt(value) };
        }
        return { type };
    });
    return validations;
}

/**
 * We need to converted for use it for create form item rules
 *
 * @param validation "required|min:1|max:3"
 * @returns [{type: "required"}, {type: "min", value: 1}, {type: "max", value: 3}]
 */
function dynamicFormItemRuleValidationMaker(validation: string) {
    const validationRules = validation.split("|");

    const result = [];
    let minMaxRule: any = { type: "minmax", minValue: false, maxValue: false };

    validationRules.forEach((rule) => {
        const [type, value] = rule.split(":");

        if (type == "min") {
            minMaxRule.minValue = parseInt(value);
        } else if (type == "max") {
            minMaxRule.maxValue = parseInt(value);
        } else {
            result.push({ type });
        }
    });

    if (
        minMaxRule.minValue !== undefined ||
        minMaxRule.maxValue !== undefined
    ) {
        result.push(minMaxRule);
    }

    return result;
}

function dynamicRuleValidationValue(validations: any[], type: string) {
    let validationByType = false;

    validations.forEach((validation) => {
        const validationMaker = dynamicInputRuleValidationMaker(validation);

        validationMaker.forEach((item: any) => {
            if (item.type == type) {
                validationByType = item.value;
            }
        });
    });

    return validationByType;
}

function dynamicRuleMaker(dynamicField: DynamicField): any[] {
    if (!Array.isArray(dynamicField.validation)) {
        return [];
    }

    return dynamicField.validation.map((validationType: string) => {
        const validationRules =
            dynamicFormItemRuleValidationMaker(validationType);
        const validations: any = [];

        if (
            dynamicField.type == "receipt-image" &&
            validationType == "required"
        ) {
            return {
                validator: validateReceiptImage,
                trigger: ["blur", "change"],
            };
        }

        if (validationType == "required") {
            return {
                required: true,
                message: "Kötelező mező!",
                trigger: ["blur", "change"],
            };
        }

        if (dynamicField.type == "number") {
            validationRules.forEach((validationRule: any) => {
                if (validationRule.type == "required") {
                    validations.push({
                        required: true,
                        message: "Kötelező mező!",
                        trigger: ["blur", "change"],
                    });
                } else if (validationRule.type == "minmax") {
                    validations.push({
                        type: "number",
                        min: validationRule.minValue,
                        max: validationRule.maxValue,
                        message: `${
                            validationRule.minValue
                                ? "Minimum " + validationRule.minValue
                                : ""
                        } ${
                            validationRule.maxValue
                                ? "Maximum " + validationRule.maxValue
                                : ""
                        }`,
                        trigger: ["blur", "change"],
                    });
                }
            });

            return validations;
        }

        return [];
    });
}

/**
 * Define emits
 */
const emit = defineEmits(["update:modelValue", "highlight-change"]);

const internalModel = computed({
    get: () => {
        if (props.modelValue == undefined) {
            return {
                dynamicFields: dynamicFieldsWithDefaultValues(),
                promotionProductsId: [],
                placeOfPurchase: "",
                receiptImage: [],
            };
        }

        props.modelValue.promotionProductsId =
            props.modelValue.promotionProductsId ?? [];
        props.modelValue.placeOfPurchase =
            props.modelValue.placeOfPurchase ?? "";
        props.modelValue.receiptImage = props.modelValue.receiptImage ?? [];

        if (!props.modelValue.dynamicFields) {
            props.modelValue.dynamicFields = {};
        }
        const dynamicFields = dynamicFieldsWithDefaultValues();

        for (let itemKey in dynamicFields) {
            props.modelValue.dynamicFields[itemKey] =
                props.modelValue.dynamicFields[itemKey] === undefined
                    ? dynamicFields[itemKey]
                    : props.modelValue.dynamicFields[itemKey];
        }

        return props.modelValue;
    },
    set: (value) => {
        emit("update:modelValue", value);
    },
});

//Setup default values

function dynamicFieldsWithDefaultValues(): { [key: string]: any } {
    let output: { [key: string]: any } = {};
    const formItems = codeUploadStore.getDynamicFiledByType(props.entryType);

    formItems.forEach((formItem) => {
        if (
            formItem.type == "receipt-image" ||
            formItem.type == "place-of-purchase" ||
            formItem.type == "purchased-products"
        ) {
            return;
        }
        if (formItem.type == "select") {
            output[dynamicFieldId(formItem)] = formItem.default ?? [];
            return;
        }
        output[dynamicFieldId(formItem)] = formItem.default ?? "";
    });

    return output;
}

const receiptImageFormItemRef = ref<any>(null);
/**
 * Revalidating all of the receipt image input fields
 */
async function receiptImageUploadChange() {
    if (!Array.isArray(receiptImageFormItemRef.value)) {
        try {
            await receiptImageFormItemRef.value.validate();
        } catch (e) {
            console.log("e", e);
        }
        return;
    }

    receiptImageFormItemRef.value.forEach(async (ref: any) => {
        try {
            await ref?.validate();
        } catch (e) {
            console.log("e", e);
        }
    });
}

function onFocus(item: string) {
    emit("highlight-change", item);
}
</script>

<template>
    <template
        v-for="formItem in codeUploadStore.getDynamicFiledByType(entryType)"
        :key="formItem.id + '-form-item-' + entryType"
    >
        <div v-if="formItem.type == 'ap-code-input'">
            <div class="row">
                <div class="col-12 col-md-8 col-lg-6 col-xxl-8">
                    <el-form-item
                        ref="dateOfPurchaseFormItemRef"
                        prop="dateOfPurchase"
                        :label="inputPurchaseDateApCodeTexts?.label"
                        :rules="[
                            {
                                required: true,
                                message: 'Kötelező mező!',
                                trigger: ['blur', 'change'],
                            },
                        ]"
                    >
                        <client-only>
                            <ApCodeDatePicker
                                :campaignStartDate="
                                    campaignDataStore.campaignData
                                        ?.campaignStart
                                "
                                :campaignEndDate="
                                    campaignDataStore.campaignData?.campaignEnd
                                "
                                v-model="
                                    codeUploadStore.codeUploadForm
                                        .dateOfPurchase
                                "
                                @focus="onFocus('date')"
                            />
                        </client-only>
                    </el-form-item>
                </div>
                <div class="col-12 col-md-4 col-lg-6 col-xxl-4">
                    <el-form-item
                        ref="timeOfPurchaseFormItemRef"
                        prop="timeOfPurchase"
                        :label="inputPurchaseTimeTexts?.label"
                        :rules="[
                            {
                                validator: validateTimeOfPurchase,
                                trigger: 'change',
                            },
                        ]"
                    >
                        <TimeOfPurchase
                            v-model="
                                codeUploadStore.codeUploadForm.timeOfPurchase
                            "
                            @focus="onFocus('time')"
                        />
                        <el-input
                            ref="timeInput"
                            class="time-mask"
                            inputmode="numeric"
                            maxlength="5"
                            :placeholder="inputPurchaseTimeTexts?.placeholder"
                            v-model="
                                codeUploadStore.codeUploadForm.timeOfPurchase
                            "
                        ></el-input>
                    </el-form-item>
                </div>
            </div>

            <el-form-item
                ref="apCodeFormItemRef"
                prop="apCode"
                :label="inputApCodeTexts?.label"
                :rules="[
                    {
                        required: true,
                        message: 'Kötelező mező!',
                        trigger: ['blur', 'change'],
                    },
                    {
                        min: 9,
                        max: 9,
                        message: 'Pontosan 9 karakterből kell hogy álljon!',
                        trigger: 'blur',
                    },
                ]"
            >
                <ApCodeInput
                    ref="apCodeInput"
                    v-model="codeUploadStore.codeUploadForm.apCode"
                    @focus="onFocus('apCode')"
                />
            </el-form-item>

            <el-form-item
                ref="receiptNumberFormItemRef"
                prop="receiptNumber"
                :label="inputReceiptNumberTexts?.label"
                :rules="[
                    {
                        required: true,
                        message: 'Kötelező mező!',
                        trigger: ['blur', 'change'],
                    },
                ]"
            >
                <el-input
                    ref="receiptNumber"
                    type="text"
                    :placeholder="inputReceiptNumberTexts?.placeholder"
                    @focus="onFocus('receipt-number')"
                    @keydown.tab="promoProductsDialog = true"
                    v-model="codeUploadStore.codeUploadForm.receiptNumber"
                ></el-input>
            </el-form-item>
        </div>

        <div v-if="formItem.type == 'invoice-input'">
            <el-form-item
                ref="dateOfPurchaseFormItemRef"
                prop="dateOfPurchase"
                :label="inputPurchaseDateApCodeTexts?.label"
                :rules="[
                    {
                        required: true,
                        message: 'Kötelező mező!',
                        trigger: ['blur', 'change'],
                    },
                ]"
            >
                <client-only>
                    <ApCodeDatePicker
                        :campaignStartDate="
                            campaignDataStore.campaignData?.campaignStart
                        "
                        :campaignEndDate="
                            campaignDataStore.campaignData?.campaignEnd
                        "
                        v-model="codeUploadStore.codeUploadForm.dateOfPurchase"
                        @focus="onFocus('date')"
                    />
                </client-only>
            </el-form-item>

            <el-form-item
                ref="invoiceFormItemRef"
                prop="invoiceNumber"
                :label="formItem?.name"
                :rules="[
                    {
                        required: true,
                        message: 'Kötelező mező!',
                        trigger: ['blur', 'change'],
                    },
                ]"
            >
                <el-input
                    type="text"
                    placeholder="Add meg a számlaszámot"
                    v-model="codeUploadStore.codeUploadForm.invoiceNumber"
                ></el-input>
            </el-form-item>
        </div>

        <div v-if="formItem.type == 'coupon-input'">
            <el-form-item
                ref="couponCodeFormItemRef"
                prop="couponCode"
                label="Kuponkód"
                :rules="[
                    {
                        required: true,
                        message: 'Kötelező mező!',
                        trigger: ['blur', 'change'],
                    },
                ]"
            >
                <el-input
                    type="text"
                    placeholder="Add meg a számlaszámot"
                    v-model="codeUploadStore.codeUploadForm.couponCode"
                ></el-input>
            </el-form-item>
        </div>

        <el-form-item
            v-if="formItem.type == 'purchased-products'"
            ref="promotionProductsFormItemRef"
            prop="promotionProductsId"
            :label="inputPurchasedPromotionPorductTexts?.label"
            :rules="dynamicRuleMaker(formItem)"
        >
            <client-only>
                <PurchasedPromotionProduct
                    :placeholder="
                        inputPurchasedPromotionPorductTexts?.placeholder ?? ''
                    "
                    v-model="internalModel.promotionProductsId"
                    @click="promoProductsDialog = true"
                    :component="codeUploadComponent?.getComponents()[5]"
                />
            </client-only>
        </el-form-item>

        <el-form-item
            v-if="formItem.type == 'place-of-purchase'"
            ref="placeOfPurchaseFormItemRef"
            prop="placeOfPurchase"
            :label="formItem?.name"
            :rules="dynamicRuleMaker(formItem)"
        >
            <client-only>
                <el-select
                    class="w-100"
                    filterable
                    :placeholder="formItem?.name"
                    v-model="internalModel.placeOfPurchase"
                >
                    <el-option
                        v-for="option in formItem.options"
                        :key="option"
                        :label="option"
                        :value="option"
                    >
                    </el-option>
                </el-select>
            </client-only>
        </el-form-item>

        <el-form-item
            v-if="formItem.type == 'receipt-image'"
            ref="receiptImageFormItemRef"
            prop="receiptImage"
            :label="formItem?.name"
            :rules="dynamicRuleMaker(formItem)"
        >
            <client-only>
                <ReceiptImageUploader
                    class="w-100"
                    v-model="internalModel.receiptImage"
                    @change="receiptImageUploadChange"
                ></ReceiptImageUploader>
            </client-only>
        </el-form-item>

        <!--Pure dynamic fields-->
        <el-form-item
            v-if="formItem.type == 'select'"
            :ref="dynamicFieldId(formItem)"
            :prop="'dynamicFields.' + dynamicFieldId(formItem)"
            :label="formItem?.name"
            :rules="dynamicRuleMaker(formItem)"
        >
            <client-only>
                <el-select
                    class="w-100"
                    filterable
                    :placeholder="formItem?.name"
                    v-model="
                        internalModel.dynamicFields[dynamicFieldId(formItem)]
                    "
                >
                    <el-option
                        v-for="option in formItem.options"
                        :key="option"
                        :label="option"
                        :value="option"
                    >
                    </el-option>
                </el-select>
            </client-only>
        </el-form-item>

        <el-form-item
            v-if="formItem.type == 'number'"
            :ref="dynamicFieldId(formItem)"
            :prop="'dynamicFields.' + dynamicFieldId(formItem)"
            :label="formItem?.name"
            :rules="dynamicRuleMaker(formItem)[0]"
        >
            <client-only>
                <el-input-number
                    class="w-100"
                    :placeholder="formItem?.name"
                    :min="
                        dynamicRuleValidationValue(formItem.validation, 'min')
                    "
                    :max="
                        dynamicRuleValidationValue(formItem.validation, 'max')
                    "
                    v-model="
                        internalModel.dynamicFields[dynamicFieldId(formItem)]
                    "
                ></el-input-number>
            </client-only>
        </el-form-item>

        <el-form-item
            v-if="formItem.type == 'text'"
            :ref="dynamicFieldId(formItem)"
            :prop="'dynamicFields.' + dynamicFieldId(formItem)"
            :label="formItem?.name"
            :rules="dynamicRuleMaker(formItem)"
        >
            <client-only>
                <el-input
                    class="w-100"
                    :placeholder="formItem?.name"
                    v-model="
                        internalModel.dynamicFields[dynamicFieldId(formItem)]
                    "
                >
                </el-input>
            </client-only>
        </el-form-item>

        <el-form-item
            v-if="formItem.type == 'textarea'"
            :ref="dynamicFieldId(formItem)"
            :prop="'dynamicFields.' + dynamicFieldId(formItem)"
            :label="formItem?.name"
            :rules="dynamicRuleMaker(formItem)"
        >
            <client-only>
                <el-input
                    class="w-100"
                    type="textarea"
                    autosize
                    :placeholder="formItem?.name"
                    v-model="
                        internalModel.dynamicFields[dynamicFieldId(formItem)]
                    "
                >
                </el-input>
            </client-only>
        </el-form-item>
        <!--END Pure dynamic fields-->
    </template>

    <!-- Dialogs -->
    <!-- Purchased promo products dialog -->
    <PurchasedPromotionProductDialog
        :show="promoProductsDialog"
        :title="inputPurchasedPromotionPorductTexts?.label ?? ''"
        :description="inputPurchasedPromotionPorductTexts?.placeholder ?? ''"
        @close="promoProductsDialog = false"
        :component="rawComponent"
    />
    <!-- Purchased promo products dialog end-->
</template>
